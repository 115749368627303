<template>
    <seccion-datos titulo="IBAN" tipo="warning" @cambios="guardar()">
            <div class="row">
                    <div class="input-group mb-2">
                        <input type="text" v-model="iban_1_proveedor" class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm">-</span>
                        <input type="text" v-model="iban_2_proveedor" class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm">-</span>
                        <input type="text" v-model="iban_3_proveedor" class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm">-</span>
                        <input type="text" v-model="iban_4_proveedor" class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm">-</span>
                        <input type="text" v-model="iban_5_proveedor" class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm">-</span>
                        <input type="text" v-model="iban_6_proveedor" class="form-control form-control-sm">
                    </div>
            </div>
</seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['proveedor', 'id'],
    data() {
        return {
            iban_1_proveedor: '',
            iban_2_proveedor: '',
            iban_3_proveedor: '',
            iban_4_proveedor: '',
            iban_5_proveedor: '',
            iban_6_proveedor: '',
        }
    },
    methods: {
        cargarDatosProveedor() {
            this.$store.dispatch('cambiarCargando', true);
            this.iban_1_proveedor = this.proveedor.iban_1_proveedor;
            this.iban_2_proveedor = this.proveedor.iban_2_proveedor;
            this.iban_3_proveedor = this.proveedor.iban_3_proveedor;
            this.iban_4_proveedor = this.proveedor.iban_4_proveedor;
            this.iban_5_proveedor = this.proveedor.iban_5_proveedor;
            this.iban_6_proveedor = this.proveedor.iban_6_proveedor;
            this.$store.dispatch('cambiarCargando', false);

        },            
       async guardar() {
           /* put   modulos/pwgsapi/index.php/proveedores/:id */
           try {
               this.$store.dispatch('cambiarGuardando', true);
               const api = new PwgsApi;
               const subidadatos = { iban_1_proveedor: this.iban_1_proveedor, iban_2_proveedor: this.iban_2_proveedor, iban_3_proveedor: this.iban_3_proveedor, iban_4_proveedor: this.iban_4_proveedor, iban_5_proveedor: this.iban_5_proveedor, iban_6_proveedor: this.iban_6_proveedor, };
               await api.put('proveedores/' + this.proveedor.id_proveedor, subidadatos);
               this.$store.dispatch('cambiarGuardando', false);
               this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
           }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

    },       
    },
    mounted() {
        this.cargarDatosProveedor();
    },
    watch: {
        proveedor() {
            this.cargarDatosProveedor();
        }
    },
}
</script>